
import { mapMutations } from 'vuex';

export default {
  name: "Loader",
  data() {
    return {
      textAnimationFinished: false,
      fontSrcReady: false
    }
  },
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished
    },
    firstHeaderText() {
      return this.$route.path === '/zavod-electroinka' ? this.$t('different in spirit') : this.$t('coffee that serves');
    },
    secondHeaderText() {
      return this.$route.path === '/zavod-electroinka' ? this.$t('magic taste') : this.$t('food that keeps');
    },
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      setTimeout(() => {
        this.initAnimationSequence();
      }, 300);
    } else {
      this.finishLoading();
    }
  },
  methods: {
    ...mapMutations({
      finishLoading: 'finishLoading'
    }),
    initAnimationSequence() {
      let SplitClient1 = this.$splitType.create(this.$refs['split1'], {
        types: "words"
      });
      let SplitClient2 = this.$splitType.create(this.$refs['split2'], {
        types: "words"
      });
      let words1 = SplitClient1.words;
      let words2 = SplitClient2.words;

      words1.forEach(word => {
        word.style.opacity = 0;
      });

      words2.forEach(word => {
        word.style.opacity = 0;
      });

      this.fontSrcReady = true;

      // Animate first part
      this.$gsap.fromTo(words1, {opacity: 0}, {
        duration: .6,
        opacity: 1,
        ease: "none",
        stagger: .2,
        onComplete: () => {
          setTimeout(() => {
            // Then animate the rest of the words
            this.$gsap.fromTo(words2, {opacity: 0}, {
              duration: .6,
              opacity: 1,
              ease: "none",
              stagger: .2,
              onComplete: () => {
                // trigger fade of content after timeout
                setTimeout(() => {
                  this.textAnimationFinished = true;
                  this.finishLoading();
                }, 750);
              }
            });
          }, 500);
        }
      });
    }
  }
}
