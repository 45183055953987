import en from './static/lang/en'
import bg from './static/lang/bg'

// default language
const defaultLocale = 'en'

// List of available language
const locales = [
  {
    code: 'en',
    iso: 'en-US',
    name: 'English'
    // isCatchallLocale: true
  },
  {
    code: 'bg',
    iso: 'bg-BG',
    name: 'Bulgaria'
    // isCatchallLocale: true
  }
]

// List of available message languages
const i18nLocales = {
  fallbackLocale: 'en',
  messages: {en, bg} // bg
}

// List of available message languages
const i18nPages = {
  'location/_slug': {
    en: '/locations/:slug',
    bg: '/lokacii/:slug',
  },
  'employment/_slug': {
    en: '/work/:slug',
    bg: '/rabota/:slug',
  },
}

export {defaultLocale, locales, i18nLocales, i18nPages}
