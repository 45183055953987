
export default {
  props: {
    animation: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(locale => locale.code !== this.$i18n.locale)
    }
  }
}
