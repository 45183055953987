
import EddyLogo from "~/static/images/eddy_logo.svg?inline";
import EddyTagline from "~/static/images/eddy_tagline.svg?inline";
import EddyElipse from "~/static/images/eddy_elipse.svg?inline";
import LightSwitch from "~/static/icons/light_switch.svg?inline";
import imgPathsMixin from "@/mixins/imageMeta";

export default {
  name: "Menu",
  components: {EddyLogo, EddyTagline, EddyElipse, LightSwitch},
  data() {
    return {
      tlMenuOverlay: null,
      tlMenuLinks: null,
      toggleMobileMenu: false,
      pages: {
        data: [
          {id: 1, attributes: {name: 'coffee', href: '#coffee', isLink: false}},
          {id: 2, attributes: {name: 'food', href: '#food', isLink: false}},
          {id: 3, attributes: {name: 'catering', href: '#catering', isLink: false}},
          {id: 4, attributes: {name: 'locations', href: '#location', isLink: false}},
          {id: 5, attributes: {name: 'join us', href: '#employment', isLink: false}},
          {id: 6, attributes: {name: 'our brand', href: '#about', isLink: false}},
        ],
      },
      year: null,
      currentColor: '',
      colors: ['light', 'dark'],
      disableBurger: false,
    };
  },
  mixins: [imgPathsMixin],
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished
    },
    showNav() {
      return this.$store.state.showNav
    },
    isHomePage() {
      // Assuming the path for home page is '/' or '/bg' for Bulgarian
      const currentPath = this.$route.path;
      return currentPath === '/' || currentPath === '/bg';
    },
    routeName() {
      const routeName = this.$route.name;

      if (routeName) {
        const match = routeName.match(/^[^-_]+/);

        return match ? match[0] : ''
      }

      return '';
    }
  },
  watch: {
    '$route'(to, from) {
      this.$store.commit('setShowNav', true)
    }
  },
  created() {
    this.disableBurger = this.$route.query.q === 'qr'
  },
  mounted() {
    const now = new Date()
    this.year = now.getFullYear()
    this.menuOverlayAnimation();
    this.menuLinksAnimation();
    window.addEventListener('resize', this.updateMenuOverlayPosition);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateMenuOverlayPosition);
  },
  methods: {
    triggerMobileNavAnimation() {
      this.toggleMobileMenu = !this.toggleMobileMenu;
      if (this.toggleMobileMenu) {
        document.body.classList.add('is-nav-open');

        this.tlMenuOverlay.play().then(() => {
          this.tlMenuLinks.play();
        });
      } else {
        this.tlMenuOverlay.reverse().then(() => {
          this.tlMenuLinks.seek(0).pause();
        });
        document.body.classList.remove('is-nav-open');
      }
      this.updateMenuOverlayPosition();
    },
    menuOverlayAnimation() {
      this.tlMenuOverlay = this.$gsap.timeline({paused: true});

      this.tlMenuOverlay.to(this.$refs['menuOverlay'], {
        x: "0",
        duration: 0.5,
        ease: "power3.inOut",
      });
    },
    menuLinksAnimation() {
      this.tlMenuLinks = this.$gsap.timeline({paused: true});

      // Combining similar animations for sides
      this.tlMenuLinks.fromTo(["#top-side", "#bottom-side"], .5,
        {
          width: 0,
          ease: "Power0.easeNone",
        },
        {
          width: "100%",
        }
      ).fromTo(["#right-side", "#left-side"], .5,
        {
          height: 0,
          ease: "Power0.easeNone",
        },
        {
          height: "100%",
        },
        "<" // Starts this animation at the same time as the previous
      );

      this.tlMenuLinks.from('.border-anim', {
        width: 0,
        duration: 0.4,
        ease: "Power0.easeNone",
        opacity: 0,
        stagger: 0
      });

      // SplitType and fade animation for each letter in menu links
      const menuLinks = document.querySelectorAll('.menu-link');
      menuLinks.forEach(link => {
        let SplitClient = this.$splitType.create(link, {
          types: "words, chars"
        });
        let chars = SplitClient.chars;

        this.tlMenuLinks.from(chars, {
          opacity: 0,
          // y: "120%",
          duration: 0.48,
          ease: "none",
          stagger: 0.02
        }, "-=0.8"); // Adjust the time offset as needed
      });
    },
    updateMenuOverlayPosition() {
      if (this.toggleMobileMenu) {
        // If the mobile menu is open, set x to 0
        this.$gsap.to(this.$refs['menuOverlay'], {x: 0});
      } else {
        // If the mobile menu is closed, set x to translate it off-screen
        this.$gsap.to(this.$refs['menuOverlay'], {x: '100%'});
      }
    },
    outsideClickHandler() {
      if (this.toggleMobileMenu) {
        this.triggerMobileNavAnimation();
      }
    },
    scrollTo(position) {
      this.triggerMobileNavAnimation();
      this.$store.dispatch('triggerScrollTo', { position, offset: 0 }); // For a specific scroll
    },
    switchColor() {
      const currentIndex = this.colors.indexOf(this.currentColor);
      const nextIndex = (currentIndex + 1) % this.colors.length;
      this.currentColor = this.colors[nextIndex];
      this.$colorMode.preference = this.currentColor;
    }
  },
}
