// plugins/swiper.js
import Vue from 'vue';
import Swiper, {Pagination, Autoplay, Navigation} from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.css';
import 'swiper/modules/pagination/pagination.scss';
// import 'swiper/modules/effect-cards/effect-cards.scss';

const swiper = {
  install(Vue, options) {
    Vue.prototype.$swiper = Swiper;
    Vue.prototype.$swiperModules = {
      Pagination,
      Autoplay,
      Navigation
    };
  }
};

Vue.use(swiper);
