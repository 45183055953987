import Vue from 'vue';
import LocomotiveScroll from 'locomotive-scroll';

Vue.prototype.$initLocomotiveScroll = (container) => {
  return new LocomotiveScroll({
    el: container,
    smooth: true,
    getDirection: true,
    // smartphone: {
    //   smooth: false,
    // },
    // tablet: {
    //   smooth: false,
    // },
  });
};
