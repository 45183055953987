export const state = () => ({
  loadingAnimationFinished: false,
  previewMode: false,
  // isMobile: false,
  isLocomotiveScrollInitialized: false,
  showNav: true,
  scrollToTarget: null,
  scrollToOffset: 0,
  galleryModal: {
    visible: false,
    type: '',
  }
})

export const mutations = {
  finishLoading(state) {
    state.loadingAnimationFinished = true;
  },
  setPreviewMode(state, value) {
    state.previewMode = value;
  },
  // setIsMobile(state, value) {
  //   state.previewMode = value;
  // },
  setLocomotiveScrollInitialized(state, value) {
    state.isLocomotiveScrollInitialized = value;
  },
  setShowNav(state, bool) {
    state.showNav = bool;
  },
  setGalleryModal(state, options) {
    state.galleryModal.type = options.type;
    state.galleryModal.visible = options.visible;
  },
  setScrollToTarget(state, options) {
    state.scrollToTarget = options.position;
    state.scrollToOffset = options.offset;
  },
}

export const actions = {
  triggerScrollTo({commit}, options) {
    commit('setScrollToTarget', options);
  }
}
