
export default {
  props: {
    menuOpen: {
      type: Boolean,
      default: false,
    },
    scaled: {
      type: Boolean,
      default: false,
    },
  },
};
