export default {
  computed: {
    alternativeText() {
      return (this.imageAttributes && this.imageAttributes.alternativeText) ? this.imageAttributes.alternativeText : '';
    },
    caption() {
      return (this.imageAttributes && this.imageAttributes.caption) ? this.imageAttributes.caption : '';
    },
    // responsiveSize() {
    //   if (!process.client) return {default: 'small', fullScreen: 'small'}; //fallback SSR
    //
    //   const screenWidth = window.innerWidth;
    //   const sizes = [
    //     {name: 'xlarge', width: 1200},
    //     {name: 'large', width: 900},
    //     {name: 'medium', width: 200},
    //     {name: 'small', width: 0},
    //   ];
    //
    //   let fullScreen = "xlarge";
    //
    //   for (let size of sizes) {
    //     if (this.imageAttributes && this.imageAttributes.formats && this.imageAttributes.formats[size.name]) {
    //       if (screenWidth >= size.width) {
    //         return {default: size.name, fullScreen: this.imageAttributes.formats[fullScreen] ? fullScreen : size.name}
    //       }
    //     }
    //     // Keep the last available size as fullscreen
    //     fullScreen = size.name;
    //   }
    //
    //   return {default: 'small', fullScreen: 'small'};   // fallback
    // },
    // imageURL() {
    //   return this.getImageOfSize(this.imageAttributes, this.responsiveSize.fullScreen);
    // },
  },
  methods: {
    getAttribute(object, path) {
      const segments = path.split('.');
      let value = object;
      for (let segment of segments) {
        if (!value) return null;
        value = value[segment];
      }
      return value;
    },
    getImageOfSize(object, size) {
      return this.getAttribute(object, `formats.${size}.url`) || '';
    },
    // getImageOfSizeAttribute(object, size, attribute) {
    //   return this.getAttribute(object, `formats.${size}.${attribute}`) || '';
    // },
    // toWebp(string) {
    //   return string ? string.replace(/\.(jpg|png)/g, '.webp') : '';
    // }
  },
};
