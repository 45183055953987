import {format, parse} from 'date-fns';
import {enUS, bg} from 'date-fns/locale';

export default (context, inject) => {
  const formatDate = (date) => {
    const formatStr = 'MMMM dd, yyyy'
    const locale = getLocaleObject(context.app.i18n.locale)
    return format(new Date(date), formatStr, {locale})
  }

  const formatTime = (timeString) => {
    // Parse the time string
    const time = parse(timeString, 'HH:mm:ss.SSS', new Date());

    // Format the time to 'H:mm' format
    return format(time, 'H:mm');
  }

  const getLocaleObject = (localeStr) => {
    switch (localeStr) {
      case 'en':
        return enUS;
      case 'bg':
        return bg;
      default:
        return enUS;
    }
  }

  // Inject formatDate into the context, so it's available globally.
  inject('formatDate', formatDate);
  inject('formatTime', formatTime);
}
