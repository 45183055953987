import Vue from 'vue';
import SplitType from 'split-type';

const splitType = {
  install(Vue, options) {
    Vue.prototype.$splitType = SplitType;
  },
};

Vue.use(splitType);
